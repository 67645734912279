import {AnalyticsApiQueryResponse} from '@/models/analytics-api';



export function getDimensionIndex(response: AnalyticsApiQueryResponse, name: string): number {
    return response.data.dimensions.map((d) => d.name).indexOf(name);
}

export function getMetricIndex(response: AnalyticsApiQueryResponse, name: string): number {
    return response.data.metrics.map((m) => m.name).indexOf(name);
}


