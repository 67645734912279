import {FormatCurrencyAmount, FormatNumber, FormatPercentage, Formatter} from '@/util/Formatter';


export class FormatterFactory {

    public static create(metricsName: string): Formatter {
        if (['te.revenue', 'te.revenueExTax', 'te.tax', 'te.eventRevenue', 'te.eventRevenueExTax', 'te.eventTax', 'te.transactionValue', 'te.paymentRevenue'].includes(metricsName)) {
            return new FormatCurrencyAmount('€');
        }
        if (metricsName === 'te.eventOccupancyRate') {
            return new FormatPercentage(1);
        }
        if (['te.transactionsPerCustomer', 'te.groupSize', 'te.visitedEvents', 'te.visitedPeriods'].includes(metricsName)) {
            return new FormatNumber(1);
        }
        if (metricsName === 'te.salesVelocityDays') {
            return new FormatNumber(1, null, 'day(s)');
        }
        return new FormatNumber(0);
    }

}
