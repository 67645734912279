
    import { Component, Vue, Emit } from 'vue-property-decorator';
    import {Action, Getter} from "vuex-class";
    import moment from "moment";
    import Icon from "@/components/Icon.vue";
    import DateContextMenu from "@/components/DateContextMenu.vue";
    import {
        DateContextEvent,
        DateContextPeriod,
        DateContextTransaction,
        DateContextType, instanceOfDateContextEvent,
        instanceOfDateContextPeriod, instanceOfDateContextTransaction, Period
    } from "@/models/date-context";
    // const namespace: string = 'core';

    @Component({
        components: {
            Icon,
            DateContextMenu,
        }
    })
    export default class DateContext extends Vue {
        private collapsedMenu: boolean = false;
        // @Action('fetchPeriods', { namespace }) fetchPeriods: any;
        // @Action('fetchDateContext', { namespace }) fetchDateContext: any;
        @Action('setDateContext') setDateContext: any;
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('periods') periods!: Period[];

        get controlIcon(): string {
            return this.collapsedMenu ? 'up' : 'down';
        }

        get label(): string {
            if(this.dateContext && instanceOfDateContextPeriod(this.dateContext)) {
                const periodId = this.dateContext ? this.dateContext.periodId : null;
                const period = this.periods.find((p) => p.id === periodId);
                return period ? period.name : '';
            }
            if(this.dateContext && (instanceOfDateContextEvent(this.dateContext) || instanceOfDateContextTransaction(this.dateContext))) {
                const dateFormat = 'D MMM YYYY';
                return moment(this.dateContext.range.min).format(dateFormat) + ' - ' + moment(this.dateContext.range.max).format(dateFormat);
            }
            return '';
        }

        get compare(): string|null {
            if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
                const periodId = this.dateContext ? this.dateContext.compareTo[0] : null;
                const period = this.periods.find((p) => p.id === periodId);
                return period ? period.name : '';
            }
            if(this.dateContext && this.dateContext.compareTo.length > 0 && (instanceOfDateContextEvent(this.dateContext) || instanceOfDateContextTransaction(this.dateContext))) {
                const dateFormat = 'D MMM YYYY';
                const compareRange = this.dateContext.compareTo[0];
                return moment(compareRange.min).format(dateFormat) + ' - ' + moment(compareRange.max).format(dateFormat);
            }
            return null;
        }

        public toggleCollapse(): void {
            this.collapsedMenu = !this.collapsedMenu;
        }

        public applyContext(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): void {
            this.setDateContext({dateContext});
            this.toggleCollapse();
            this.emitApply(dateContext);
        }

        @Emit("apply")
        public emitApply(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): DateContextPeriod|DateContextEvent|DateContextTransaction|void {
          return dateContext;
        }

        mounted(): void {
            // this.fetchDateContext();
            // this.fetchPeriods();
        }

    }
