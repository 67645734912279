import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {SeriesData, DataPoint, PieSeriesData, PieDataPoint} from '@ticketengine/chart';
import {SegmentSummery} from '@/models/segment';
import moment from 'moment';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, instanceOfDateContextEvent, instanceOfDateContextTransaction, Period} from '@/models/date-context';
import {cartesian} from '@//util/CartesianProduct';

/**
 * Convert Analytics API responses into series data for plotting
 */
export abstract class SeriesBuilder {
    protected _colors: string[];
    protected _response: AnalyticsApiQueryResponse;
    protected _segments?: SegmentSummery[];
    protected _periods?: Period[];
    protected _dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;

    protected constructor(
        response: AnalyticsApiQueryResponse,
        segments?: SegmentSummery[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
    ) {
        // this._colors = ['#FFAE00', '#1693A7', '#2ED362', '#C8CF02', '#97BEA9'];
        this._colors = ['#FFAE00', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'];
        this._segments = segments;
        this._periods = periods;
        this._dateContext = dateContext;
        this._response = response;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Helpers
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    protected _getDimensionIndex(name: string): number {
        return this._response.data.dimensions.map((d) => d.name).indexOf(name);
    }

    protected _getMetricIndex(name: string): number {
        return this._response.data.metrics.map((m) => m.name).indexOf(name);
    }

    protected _getUniqueDimensionValues(name: string): string[] {
        const dimensionIndex = this._getDimensionIndex(name);
        return this._response.data.rows.map((r) => r.dimensions[dimensionIndex]).filter((id, i, self) => self.indexOf(id) === i);
    }

    protected _formatDimensionValue(value: string, dimension: string): string {
        // !!!!!!!!!!!!!!!!!!!! DIT HOEFT STRAKS WELLICHT NIET MEER !!!!!!!!!!!!!!!!!!!!
        const dateFormat = 'D MMM YY';
        if(dimension === 'te.segment' && this._segments) {
            const segment = this._segments.find((s) => s.id === value);
            if(segment) {
                return String(segment.name);
            }
        }
        if(dimension === 'te.period' && this._periods) {
            const period = this._periods.find((p) => p.id === value);
            if(period) {
                return period.name;
            }
        }
        if(dimension === 'te.dateRange' && this._dateContext && instanceOfDateContextTransaction(this._dateContext) && value === '0') {
            // first date range
            return moment(this._dateContext.range.min).format(dateFormat) + ' - ' + moment(this._dateContext.range.max).format(dateFormat);
        }
        if(dimension === 'te.dateRange' && this._dateContext && instanceOfDateContextTransaction(this._dateContext) && value === '1') {
            // second (compare to) date range
            return moment(this._dateContext.compareTo[0].min).format(dateFormat) + ' - ' + moment(this._dateContext.compareTo[0].max).format(dateFormat);
        }
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        return value;
    }

    protected _getDimensionsValuesCartesianProduct(dimensions: string[]): string[][] {
        const dimensionsValues = dimensions.map((d) => this._getUniqueDimensionValues(d));
        const cp = cartesian(dimensionsValues); // cartesian product of values
        return cp.map((a) => a.map((v, i) => this._formatDimensionValue(v, dimensions[i]))); // format values in cartesian product
    }

    protected _findRowByDimension(query: RowQueryByDimension[]): Row|null {
        const row = this._response.data.rows.find((r) => {
            for (let i = 0; i < query.length; i++) {
                const dimensionIndex = this._getDimensionIndex(query[i].name);
                // if (r.paymentMethodDimensions[dimensionIndex] !== reportOrderLine[i].value) {
                if (this._formatDimensionValue(r.dimensions[dimensionIndex], query[i].name) !== query[i].value) { // search for formatted dimension value, row above searched for original dimension value
                    return false;
                }
            }
            return true;
        });
        return row ? row : null;
    }

    protected _getDataPointValue(map: ValueMap, row: Row): string {
        if (map.mapType === MapType.dimension) {
            return row.dimensions[this._getDimensionIndex(map.field)];
        }
        if (map.mapType === MapType.metric) {
            return row.metrics[this._getMetricIndex(map.field)];
        }
        throw new Error('Invalid map type.');
    }

    protected _castValueToType(map: ValueMap, value: any): number|Date|string {
        if (map.valueType === ValueType.int) {
            return parseInt(value);
        }
        if (map.valueType === ValueType.float) {
            return parseFloat(value);
        }
        if (map.valueType === ValueType.date) {
            return moment(value).toDate();
        }
        if (map.valueType === ValueType.string) {
            return String(value);
        }
        throw new Error('Invalid value type.');
    }

    protected _castValueToNumber(map: ValueMap, value: any): number {
        if (map.valueType === ValueType.int) {
            return parseInt(value);
        }
        if (map.valueType === ValueType.float) {
            return parseFloat(value);
        }
        throw new Error('Invalid value type.');
    }

    protected _castValueToString(map: ValueMap, value: any): string {
        if (map.valueType === ValueType.string) {
            return String(value);
        }
        throw new Error('Invalid value type.');
    }

    protected _generateId(): string {
        return '_' + Math.random().toString(36).substr(2, 9);
    }





    protected _getUniqueDateContextDimensionValues(): string[] {
        // const dimensionIndex = this._getDimensionIndex(this._getDateContextDimensionName());
        // return this._response.data.paymentMethodRows.map((r) => r.paymentMethodDimensions[dimensionIndex]).filter((id, i, self) => self.indexOf(id) === i);
        return this._getUniqueDimensionValues(this._getDateContextDimensionName());
    }

    protected _getDateContextDimensionName(): string {
        if (this._response.data.dimensions.map((d) => d.name).includes('te.period')) {
            return 'te.period';
        }
        return 'te.dateRange';
    }

    protected _getSegmentsInData(): SegmentSummery[] {
        if (!this._segments) {
            throw new Error('Segments are not supplied.');
        }
        const uniqueSegmentIds = this._getUniqueDimensionValues('te.segment');
        // return this._segments.filter((s) => uniqueSegmentIds.includes(s.id));
        return this._segments.filter((s) => uniqueSegmentIds.includes(String(s.name)));
    }

    protected _getPeriodsInData(): Period[] {
        const uniquePeriodIds = this._getUniqueDimensionValues('te.period');
        // return this._periods ? this._periods.filter((s) => uniquePeriodIds.includes(s.id)) : [];
        return this._periods ? this._periods.filter((s) => uniquePeriodIds.includes(String(s.name))) : [];
    }

    protected _getSegmentContext(dateContextDimensionValue?: string): string|null {
        const dateFormat = 'D MMM YY';
        if (this._getUniqueDateContextDimensionValues().length > 1) {
            if(this._response.data.dimensions.map((d) => d.name).includes('te.period')) {
                // period date context
                const periods = this._getPeriodsInData();
                const period = periods.find((p) => p.id === dateContextDimensionValue);
                return period ? period.name : '';
            } else if(this._dateContext && (instanceOfDateContextTransaction(this._dateContext) || instanceOfDateContextEvent(this._dateContext)) && dateContextDimensionValue === '0') {
                // first date range
                return moment(this._dateContext.range.min).format(dateFormat) + ' - ' + moment(this._dateContext.range.max).format(dateFormat);
            } else if(this._dateContext && (instanceOfDateContextTransaction(this._dateContext) || instanceOfDateContextEvent(this._dateContext)) && dateContextDimensionValue === '1') {
                // second (compare to) date range
                return moment(this._dateContext.compareTo[0].min).format(dateFormat) + ' - ' + moment(this._dateContext.compareTo[0].max).format(dateFormat);
            }
        }
        return null;
    }

    /**
     * Return paymentMethodRows for supplied segment and that are in the date context
     * @param segmentId
     * @param dateContextDimensionValue
     * @private
     */
    protected _getFilteredRows(segmentName: string, dateContextDimensionValue?: string): Row[] {
        const segmentDimensionIndex = this._getDimensionIndex('te.segment');
        const dateContextDimensionIndex = this._getDimensionIndex(this._getDateContextDimensionName());
        return this._response.data.rows.filter((r) => {
            const inSegment = r.dimensions[segmentDimensionIndex] === segmentName;
            const inDateContextDimension = dateContextDimensionValue && r.dimensions[dateContextDimensionIndex] === dateContextDimensionValue;
            return inSegment && ((dateContextDimensionValue && inDateContextDimension) || !dateContextDimensionValue);
        });
    }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Setters
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    set colors(value: string[]) {
        this._colors = value;
    }

    set response(value: AnalyticsApiQueryResponse) {
        this._response = value;
    }

    set segments(value: SegmentSummery[]) {
        this._segments = value;
    }

    set periods(value: Period[]) {
        this._periods = value;
    }

}


export enum MapType {
    'dimension' = 'dimension',
    'metric' = 'metric',
}

export enum ValueType {
    'int' = 'int',
    'float' = 'float',
    'string' = 'string',
    'date' = 'date',
}

export interface ValueMap {
    mapType: MapType;
    valueType: ValueType;
    field: string;
}

export interface RowQueryByDimension {
    name: string; // name of the dimension
    value: string; // value of the dimension to look for
}
