import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {
    DateContext, DateContextEvent, DateContextPeriod,
    DateContextTransaction, DateRange,
} from '@/models/date-context';
import {getDateDifferance, parseDate} from '@/util/DateUtil';
import {
    getCompareDateRange,
    getDateContextCompareStart,
    getDateContextStart,
    getDateRange,
    hasCompareDateRange,
} from '@/util/DateContextUtil';
import {isDateDimension, translateDimensionMetricName} from '@/util/MetricsAndDimensionsUtil';
import {getDimensionIndex, getMetricIndex} from '@/util/AnalyticsApiQueryResponseUtil';


export class GChartSeriesBuilder {
    protected response: AnalyticsApiQueryResponse;
    protected dateRangeDimension: string;
    protected dateContext: DateContext | undefined;
    protected dimensions: string[];
    protected metrics: string[];

    constructor(response: AnalyticsApiQueryResponse) {
        this.response = response;
        this.dateRangeDimension = 'te.dateRange';
        this.dimensions = this.getDefaultDimensions();
        this.metrics = this.getDefaultMetrics();
    }

    public setDateRangeDimension(dimension: string): GChartSeriesBuilder {
        this.dateRangeDimension = dimension;
        return this;
    }

    public setDateContext(context: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined): GChartSeriesBuilder {
        this.dateContext = context;
        return this;
    }

    public setDimensions(dimensions: string[]): GChartSeriesBuilder {
        this.dimensions = dimensions;
        return this;
    }

    public setMetrics(metrics: string[]): GChartSeriesBuilder {
        this.metrics = metrics;
        return this;
    }

    public build(): any[] {
        const data: any[] = [];
        this.populateHeaderData(data);
        this.populateRowsData(data);
        return data;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Returns the unique values of the dimension. If dimension is a date dimension and the compare date range is set
     * the function will only return the unique values of the primary date range. The values of the compare date range will be ignored.
     */
    private getUniqueDimensionValues(name: string): string[] {
        let rows = this.response.data.rows;
        if (hasCompareDateRange(this.dateContext) && isDateDimension(name)) {
            rows = this.response.data.rows.filter((row) => row.dimensions[getDimensionIndex(this.response, this.dateRangeDimension)] === '0');
        }
        return rows.map((r) => r.dimensions[getDimensionIndex(this.response, name)]).filter((id, i, self) => self.indexOf(id) === i);
    }


    /**
     * Returns an array of series. Every bar or line in the chart is series. If a series represents a extra dimension then the
     * dimension prop in the series is set. If the series represents a additional metric the metric prop is set.
     * If a compare date range is set then the series count will double, because a new dimension is added for the date range.
     */
    private getSeries(): Array<{ dimension: string | null, metric: string | null, value: any, label: string, isCompare: boolean }> {
        let seriesValues: Array<{ dimension: string | null, metric: string | null, value: any, label: string, isCompare: boolean }> = [];
        if (this.hasBreakdownDimensions()) {
            // dimensions series
            seriesValues = this.getUniqueDimensionValues(this.dimensions[1]).map((value) => {
                return {dimension: this.dimensions[1], metric: null, value, label: value, isCompare: false};
            });
        } else {
            // metrics series
            seriesValues = this.metrics.map((metric) => {
                return {
                    dimension: null,
                    metric,
                    value: translateDimensionMetricName(metric),
                    label: translateDimensionMetricName(metric),
                    isCompare: false,
                };
            });
        }

        if (hasCompareDateRange(this.dateContext)) {
            // add date range context to series label
            const dateRange = getDateRange(this.dateContext);
            seriesValues = seriesValues.map((s) => {
                s.label = dateRange ? s.value + ` (${this.formatDateRange(dateRange)})` : s.value;
                return s;
            });

            // add compare date range series
            const seriesValuesCopy = [...seriesValues];
            const compareDateRange = getCompareDateRange(this.dateContext);
            seriesValuesCopy.forEach((s) => {
                seriesValues.push({
                    dimension: s.dimension,
                    metric: s.metric,
                    value: s.value,
                    label: compareDateRange ? s.value + ` (${this.formatDateRange(compareDateRange)})` : s.value,
                    isCompare: true,
                });
            });
        }
        return seriesValues;
    }

    private findRow(dimensionQuery: Array<{ name: string, value: string, operator?: string }>): Row | undefined {
        return this.response.data.rows.find((row) => {
            for (const item of dimensionQuery) {
                if ((row.dimensions[getDimensionIndex(this.response, item.name)] !== item.value) && !item.operator) {
                    return false;
                }
                if (item.operator === 'offsetInDays') {
                    const dccs = getDateContextCompareStart(this.dateContext);
                    const dateString = row.dimensions[getDimensionIndex(this.response, item.name)];
                    if (dccs && String(getDateDifferance(dccs, parseDate(dateString), 'days')) !== item.value) {
                        return false;
                    }
                }
            }
            return true;
        });
    }

    private populateHeaderData(data: any[]): void {
        const headerData: any[] = [];
        headerData.push(translateDimensionMetricName(this.dimensions[0]));
        this.getSeries().forEach((s) => {
            headerData.push(s.label);
        });
        data.push(headerData);
    }

    private populateRowsData(data: any[]): void {
        const uniqueDimensionValues = (isDateDimension(this.dimensions[0])) ? this.getUniqueDimensionValues(this.dimensions[0]).sort() : this.getUniqueDimensionValues(this.dimensions[0]);
        // this.getUniqueDimensionValues(this.dimensions[0]).sort().forEach((value) => { // sort on primary dimension values
        // this.getUniqueDimensionValues(this.dimensions[0]).forEach((value) => {
        uniqueDimensionValues.forEach((value) => {
            const rowData: any[] = [];
            rowData.push(this.formatDimensionValue(this.dimensions[0], value)); // add x value
            this.getSeries().forEach((s) => { // add series values
                const query: Array<{ name: string, value: string, operator?: string }> = [];
                if (s.dimension) {
                    query.push({name: s.dimension, value: s.value});
                }
                if (hasCompareDateRange(this.dateContext) && s.isCompare && this.dateRangeDimension === 'te.dateRange') {
                    query.push({name: this.dateRangeDimension, value: '1'});
                }
                if (hasCompareDateRange(this.dateContext) && !s.isCompare && this.dateRangeDimension === 'te.dateRange') {
                    query.push({name: this.dateRangeDimension, value: '0'});
                }
                const dcs = getDateContextStart(this.dateContext);
                if (hasCompareDateRange(this.dateContext) && s.isCompare && isDateDimension(this.dimensions[0]) && dcs) {
                    // query on compare date dimension value
                    query.push({
                        name: this.dimensions[0],
                        value: String(getDateDifferance(dcs, parseDate(value), 'days')),
                        operator: 'offsetInDays',
                    });
                } else {
                    // default query: query on primary dimension value, do not query on primary date dimension in case of a compare series
                    query.push({name: this.dimensions[0], value});
                }

                const row = this.findRow(query);
                if (row && s.dimension) {
                    rowData.push(this.formatMetricValue(row.metrics[getMetricIndex(this.response, this.metrics[0])]));
                } else if (row && s.metric) {
                    rowData.push(this.formatMetricValue(row.metrics[getMetricIndex(this.response, s.metric)]));
                } else {
                    rowData.push(0);
                }
            });

            data.push(rowData);
        });

    }


    private hasBreakdownDimensions(): boolean {
        return this.dimensions.length > 1;
    }

    private formatDateRange(dateRange: DateRange): string {
        return `${dateRange.min.getFullYear()}-${dateRange.min.getMonth() + 1}-${dateRange.min.getDate()} ~ ${dateRange.max.getFullYear()}-${dateRange.max.getMonth() + 1}-${dateRange.max.getDate()}`;
    }

    private formatDimensionValue(dimension: string, value: string): any {
        if (isDateDimension(dimension)) {
            return parseDate(value);
        }
        return value;
    }

    private formatMetricValue(value: string): number {
        return Number(value);
    }

    private getDefaultDimensions(): string[] {
        if (this.response.data.dimensions.length > 0) {
            return [this.response.data.dimensions[0].name];
        }
        return [];
    }

    private getDefaultMetrics(): string[] {
        if (this.response.data.metrics.length > 0) {
            return [this.response.data.metrics[0].name];
        }
        return [];
    }

}
