
import {GChart} from 'vue-google-charts/legacy';
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {colors} from '@/config/common';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {GChartSeriesBuilder} from '@/util/GChartSeriesBuilder';
import {
  DateContext,
  DateContextEvent,
  DateContextPeriod,
  DateContextTransaction,
} from '@/models/date-context';
// import BaseChart from '@/components/Chart/BaseChart.vue';
// import {SeriesData,ChartBuilder,ChartType,Chart} from "@ticketengine/chart";
// import {ChartLegendSeries} from '@/models/chart';

@Component({
  components: {
    GChart,
    // BaseChart,
  }
})
export default class LineChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
    return new GChartSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();
  }

  get chartOptions(): any {
    const defaultOptions = {
      // title: 'Company Performance',
      // curveType: 'function',
      legend: {position: 'top'},
      // legend: { position: 'bottom' },
      // width: 800,
      height: 500,
      // colors: ['#FFAE00', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'],
      colors: colors,
      fontName: 'heebo',
      fontSize: 14,
      // selectionMode: 'multiple',
      // tooltip: {trigger: 'selection'},
      // aggregationTarget: 'category',
      chartArea:{width:'92%'},
      focusTarget: 'category',
      hAxis: {
        title: this.hasDimensions ? this.$t('metricsAndDimensions.name.'+this.dimensions[0]) : '',
        // title: 'Time'
        // format: 'short',
        gridlines: {
          color: '#D0DCE2',
          // minSpacing: 20
        },
        minorGridlines: {
          count: 0
        },
      },
      vAxis: {
        // title: 'Popularity'
        minorGridlines: {
          count: 0
        },
      },
    };
    return Object.assign(defaultOptions, this.options);
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }

  // @Prop({default:3}) private aspectRatioWidth!: number;
  // @Prop({default:1}) private aspectRatioHeight!: number;
  // @Prop() private series!: SeriesData[];
  // // @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
  // @Ref('chart-container') readonly chartContainer!: Element;
  // private chart?: Chart;

  // get legendItems(): ChartLegendSeries[] {
  //     return this.series.map((d) => {
  //         return {
  //             name: d.name,
  //             context: d.context,
  //             color: d.color,
  //         }
  //     });
  // }

  // get hasSeries(): boolean {
  //     return this.series && this.series.length > 0;
  // }

  // private renderChart(): void {
  //     // clear chart if one exists
  //     if(this.chart) {
  //         this.chart.clear();
  //     }
  //
  //     if(this.hasSeries) {
  //         // init chart
  //         const width = this.$el.clientWidth;
  //         const height = this.$el.clientWidth/(this.aspectRatioWidth/this.aspectRatioHeight);
  //
  //         // build chart
  //         const builder = new ChartBuilder({
  //             el: this.chartContainer,
  //             width: width,
  //             height: height,
  //             data: this.series,
  //             type: ChartType.line,
  //             margin: {top: 20, right: 20, bottom: 20, left: 40}
  //         });
  //         this.chart = builder.build();
  //     }
  // }


  // @Watch('series')
  // seriesChanged(newSeries: SeriesData[]) {
  //     this.renderChart();
  // }

  // created(): void {
  //     this.$nextTick(() => {
  //         if(this.series && this.series.length > 0) {
  //             this.renderChart();
  //         }
  //     });
  // }
}
