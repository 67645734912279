import {
    DateContext,
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction,
    DateRange, instanceOfDateContextEvent, instanceOfDateContextTransaction
} from '@/models/date-context';


export function getDateRange(dateContext: DateContext|DateContextTransaction|DateContextEvent|DateContextPeriod|null|undefined): DateRange|null {
    if (dateContext && (instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext))) {
        return dateContext.range;
    }
    return null;
}

export function getCompareDateRange(dateContext: DateContext|DateContextTransaction|DateContextEvent|DateContextPeriod|null|undefined): DateRange|null {
    if (dateContext && (instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext)) && dateContext.compareTo.length > 0) {
        return dateContext.compareTo[0];
    }
    return null;
}

export function hasCompareDateRange(dateContext: DateContext|DateContextTransaction|DateContextEvent|DateContextPeriod|null|undefined): boolean {
    if (dateContext && (instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext))) {
        return dateContext.compareTo.length > 0;
    }
    return false;
}

export function getDateContextStart(dateContext: DateContext|DateContextTransaction|DateContextEvent|DateContextPeriod|null|undefined): Date|null {
    if (dateContext && (instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext))) {
        return dateContext.range.min;
    }
    return null;
}

export function getDateContextCompareStart(dateContext: DateContext|DateContextTransaction|DateContextEvent|DateContextPeriod|null|undefined): Date|null {
    if (dateContext && (instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext))) {
        return dateContext.compareTo[0].min;
    }
    return null;
}

