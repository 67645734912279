import i18n from '@/i18n';


export function translateDimensionMetricName(name: string): string {
    return String(i18n.t('metricsAndDimensions.name.' + name));
}


export function isDateDimension(dimension: string): boolean {
    return ['te.transactionDate', 'te.transactionDateHour', 'te.transactionDateHourMinute', 'te.eventStartDate', 'te.eventStartDateHour', 'te.eventStartDateHourMinute', 'te.eventEndDate', 'te.eventEndDateHour', 'te.eventEndDateHourMinute'].includes(dimension);
}


export function isSegmentDimension(dimension: string): boolean {
    return dimension === 'te.segment';
}



