
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import Icon from "@/components/Icon.vue";

    @Component({
        components: {
            Icon,
        }
    })
    export default class IconButton extends Vue {
        @Prop({required: true, type: String}) private icon!: string;
        @Prop() private label!: string;
        @Prop({default: 15}) private size!: number;
        @Prop() private count!: number;
        @Prop({default: false}) private alternative!: boolean;
        @Prop({default: false}) private blendBackground!: boolean;
        @Prop({default: false}) private isActive!: boolean;
        @Prop({default: false}) private isDisabled!: boolean;

        // get iconFill(): string {
        //     return this.fill ?? '#273945';
        // }

        @Emit("click")
        public onClick(): void {
            if(!this.isDisabled) {
                return;
            }
        }

    }
