
    import {Component, Vue, Emit, Watch, Prop} from "vue-property-decorator";
    // import {Action, Getter} from "vuex-class";
    import BasicTable from '@/components/BasicTable.vue';
    import Button from "@/components/Button.vue";
    import {SegmentSummery} from '@/models/segment';
    import {TableColumn} from '@/models/table';
    const namespace: string = 'core';

    @Component({
        components: {
            BasicTable,
            'te-button': Button,
        }
    })
    export default class SegmentPicker extends Vue {
        private selected: SegmentSummery[] = [];
        // @Getter('segments') segments?: SegmentSummery[];
        // @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
        // @Action('selectSegments') selectSegments: any;
        @Prop() private segments!: SegmentSummery[];
        @Prop() private selectedSegments!: SegmentSummery[];

        get columns(): TableColumn[] {
            return [
                {key: 'name', label: this.$t('common.name'), link: {routeName: 'segment-detail-edit', params: [{paramKey: 'id', valueKey: 'id'}]}},
                {key: 'type', label: this.$t('common.type')},
                // {key: 'count', label: this.$t('common.ticketBuyers'), formatter: (v) => v + ' ' + this.$t('common.ticketBuyers').toLowerCase()},
                {key: 'count', label: this.$t('common.ticketBuyers')},
            ];
        }

        // public onSegmentSave(segment: Segment): void {
        //     console.log(segment);
        // }

        public onSelectSegment(segments: SegmentSummery[]): void {
            // this.selectSegments({segments});
            this.selected = segments;
        }

        public onCreateNew(): void {
            // this.$router.push({name:'segment-detail', params: {id: 'new'}});
            this.$router.push({name:'segment-detail-edit', params: {id: 'new'}});
        }

        @Watch('selectedSegments')
        nameChanged(newVal: SegmentSummery[]) {
            this.selected = newVal
        }

        @Emit("close")
        public onClose(): void {
            return;
        }

        @Emit("apply")
        public onApply(): SegmentSummery[] {
            return this.selected;
        }

        mounted() {
            this.selected = this.selectedSegments;
        }

    }
