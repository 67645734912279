import {SeriesBuilder} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';


export class CsvSeriesBuilder extends SeriesBuilder {
    private dimensions: string[];
    private metrics: string[];
    private metadataDimensions: string[];

    constructor(
        response: AnalyticsApiQueryResponse,
        segments: SegmentSummery[],
        dimensions: string[],
        metrics: string[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
        metadataDimensions?: string[],
    ) {
        super(response, segments, periods, dateContext);
        this.dimensions = dimensions;
        this.metrics = metrics;
        this.metadataDimensions = metadataDimensions ?? [];
    }

    public build(): object[] {
        const d: object[] = [];
        if (this._response && this._response.data && this._response.data.rows) {
            this._response.data.rows.forEach((row) => {
                d.push([...this.getRowDimensionValues(row), ...this.getRowMetadataDimensionValues(row), ...this.getRowMetricValues(row)].reduce((o, v) => {
                    o = Object.assign(o, v);
                    return o;
                }));
            });
        }
        return d;
    }


    private getRowDimensionValues(row: Row): object[] {
        const mapDimensionValue = this.mapDimensionValue.bind(this); // bind this context to map
        const returnDimensionIndexes = this.dimensions.map((rd) => this._response.data.dimensions.map((dd) => dd.name).indexOf(rd)); // dimension indexes of the paymentMethodDimensions that need to be returned
        return row.dimensions
            .map((d, i) => ({value: d, index: returnDimensionIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
            .filter((d) => d.index >= 0) // filter out values that are not in returnDimensionIndexes
            .sort((a, b) => a.index - b.index) // sort array in order of returnDimensionIndexes
            .map((d) => d.value) // return only values
            .map(mapDimensionValue); //
    }


    private getRowMetadataDimensionValues(row: Row): object[] {
        const mapMetadataDimensionValue = this.mapMetadataDimensionValue.bind(this); // bind this context to map
        const returnDimensionIndexes = this.metadataDimensions.map((rd) => this._response.data.dimensions.map((dd) => dd.name).indexOf(rd)); // dimension indexes of the paymentMethodDimensions that need to be returned
        return row.dimensions
            .map((d, i) => ({value: d, index: returnDimensionIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
            .filter((d) => d.index >= 0) // filter out values that are not in returnDimensionIndexes
            .sort((a, b) => a.index - b.index) // sort array in order of returnDimensionIndexes
            .map((d) => d.value) // return only values
            .map(mapMetadataDimensionValue); //
    }


    private getRowMetricValues(row: Row): object[] {
        const returnMetricIndexes = this.metrics.map((rm) => this._response.data.metrics.map((dm) => dm.name).indexOf(rm)); // metric indexes of the paymentMethodMetrics that need to be returned
        return row.metrics
            .map((d, i) => ({value: d, index: returnMetricIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
            .filter((d) => d.index >= 0) // filter out values that are not in returnMetricIndexes
            .sort((a, b) => a.index - b.index) // sort array in order of returnMetricIndexes
            .map((d, i) => {
                const mv: {[k: string]: any} = {};
                mv[this.metrics[i].replace('te.', '')] = d.value;
                return mv;
            });  // return only values
    }


    /**
     * Replace segment and period id with name, else return provided value
     * @param value
     * @param index
     */
    private mapDimensionValue(value: string, index: number): object {
        const dm: {[k: string]: any} = {};
        dm[this.dimensions[index].replace('te.', '')] = this._formatDimensionValue(value, this.dimensions[index]);
        return dm;
    }

    /**
     * Replace segment and period id with name, else return provided value
     * @param value
     * @param index
     */
    private mapMetadataDimensionValue(value: string, index: number): object {
        const dm: {[k: string]: any} = {};
        dm[this.metadataDimensions[index].replace('te.', '')] = this._formatDimensionValue(value, this.metadataDimensions[index]);
        return dm;
    }

}
