
class ExportService {

    public csv(data: object[], filename: string): void {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += [
            Object.keys(data[0]).join(';'),
            // ...data.map(item => Object.values(item).join(';'))
            ...data.map((item) => Object.values(item).join('|||||').split(';').join('').split('|||||').join(';')),
        ]
            .join('\n')
            .replace(/(^\[)|(\]$)/gm, '');

        const d = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', d);
        link.setAttribute('download', filename);
        link.click();
    }

}

export default new ExportService();
