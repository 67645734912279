
    import {Component, Vue, Emit} from "vue-property-decorator";
    import {Getter, Action} from "vuex-class";
    import SegmentCart from "@/components/SegmentCart.vue";
    import SegmentPicker from "@/components/SegmentPicker.vue";
    import {SegmentSummery, SegmentType} from "@/models/segment";
    // const namespace: string = 'core';

    @Component({
        components: {
            SegmentPicker,
            SegmentCart,
        }
    })
    export default class SegmentContext extends Vue {
        private newSegment: SegmentSummery = {
            id: 'new',
            type: SegmentType.new,
            name: this.$t('segment.add'),
        };
        private openManagement: boolean = false;
        @Getter('segments') segments?: SegmentSummery[];
        @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
        // @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        @Action('selectSegments') selectSegments: any;


        public toggleManagement(): void {
            this.openManagement = !this.openManagement;
        }

        public onEdit(segment: SegmentSummery): void {
            this.toggleManagement();
        }

        public onAdd(segment: SegmentSummery): void {
            this.toggleManagement();
        }

        public onApply(segments: SegmentSummery[]): void {
            this.selectSegments({segments});
            this.toggleManagement();
            this.emitApply();
        }

        @Emit("apply")
        public emitApply(): void {
            return;
        }

        mounted() {
            // this.fetchSegmentSummaries();
        }

    }
