
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import {
      DimensionTableDimension,
      DimensionTableMetric,
      DimensionTableRow,
      DimensionTableLink,
      RouterParams,
      DimensionTableMetadataColumn, DimensionTableInternalRow, DimensionValue
    } from '@/models/table';
    import {TranslateResult} from 'vue-i18n';

    @Component
    export default class DimensionTable extends Vue {
        @Prop() private dimensions!: DimensionTableDimension[];
        @Prop() private metrics!: DimensionTableMetric[];
        @Prop() private rows!: DimensionTableRow[]; // the actual data that needs to be displayed
        @Prop() private link!: DimensionTableLink;
        @Prop() private metadata!: string[]; // name of the metadata dimensions ex.: te.eventId
        @Prop() private metadataColumns!: DimensionTableMetadataColumn[]; // names of items in provided metadata that need to be rendered as a column
        private sortColumn: string|null = null;
        private sortDirection: string = 'asc';


        get tableRows(): Array<{name: string; dimension: string[]; metrics: string[]; metadata: string[]}> {
          const rows: DimensionTableInternalRow[] = [];
          const dimensionValues = this.getDimensionValues();
          dimensionValues[0] = this.sortDimensionValues(dimensionValues[0], this.dimensions[0], this.tableSortColumn, this.sortDirection);
          this.populateRows(dimensionValues, rows);
          return rows;
        }


        get tableColumns(): Array<{name: string, label: string | TranslateResult; description?: string | TranslateResult;}> {
            return [
                {name: '', label: '', description: ''},
                ...this.metadataColumns ? this.metadataColumns.map((m) => { return {name: m.name, label: m.label, description: m.description} }) : [],
                ...this.metrics.map((m) => { return {name: m.name, label: m.label, description: m.description} })
            ];
        }


        get tableSortColumn(): string {
          if (this.sortColumn !== null) {
            return this.sortColumn;
          }
          return this.dimensions[0].name;
        }


        get noData(): boolean {
            if(this.tableRows && this.tableRows.length > 0) {
                return false;
            }
            return true;
        }


        public setSort(column: string): void {
            if(this.sortColumn === column) {
              this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
              this.sortColumn = column;
              this.sortDirection = 'asc';
            }
        }


        public getMetadata(row: {name: string; dimension: string[]; metrics: string[]; metadata: string[]}): string[] {
          const metadataColumns = this.metadataColumns ?? [];
          if(row.metadata.length > 0 && this.isPrimaryDimensionRow(row)) {
            const metadataIndexesToDisplay = this.metadata.filter(x => metadataColumns.map(m => m.name).includes(x)).map(v => this.metadata.indexOf(v)); // get index of metadataColumns items in metadata
            return row.metadata.filter((m, i) => metadataIndexesToDisplay.includes(i)); // only return metadata that is in metadataColumns
          }
          return metadataColumns.map(m => '');
        }


        public getMetrics(metrics: string[]): string[] {
            if(metrics.length > 0) {
                return metrics;
            }
            return this.metrics.map(m => '');
        }

      /**
       * Returns the unique dimension values for each dimension.
       * The primary dimension values are only unique on dimension and metadata dimensions values combination.
       */
      public getDimensionValues(): DimensionValue[][] {
            const dimensionValues: DimensionValue[][] = [];
            this.dimensions.forEach((dimension, index) => {
                let values: DimensionValue[] = [];
                if(index === 0) { // primary dimension
                    const uniquePrimaryRowKeys = this.rows.map((row) => [row.dimensions[0], ...row.metadata].join('|')).filter((k, i, self) => self.indexOf(k) === i);
                    uniquePrimaryRowKeys.map((key) => {
                        const dimensions = key.split('|');
                        const primaryDimension = dimensions[0];
                        const metadata = dimensions.slice(1, dimensions.length)
                        values.push({value: primaryDimension, metadata: metadata});
                    });
                } else { // other dimensions
                  values = this.rows.map((row) => row.dimensions[index]).filter((d, i, self) => self.indexOf(d) === i).map((value) => {return {value, metadata: []}})
                }
                dimensionValues.push(values);
            });
            return dimensionValues;
        }

      /**
       *
       */
        public populateRows(dimensionValues: DimensionValue[][], rows: DimensionTableInternalRow[], dimension: string[] = []): void {
            if(dimensionValues.length > 0) {
                for(let i = 0; i < dimensionValues[0].length; i++) {
                    const currentDimension = [...dimension, ...[dimensionValues[0][i].value]]; // create current dimension by coping supplied dimension and adding the current dimension value
                    if(dimensionValues.length === 1) { // check if is last dimension in dataset
                        // rows.push({name: dimensionValues[0][i].value, dimension: currentDimension, metrics: this.getDimensionMetrics(currentDimension), metadata: this.getDimensionMetadata(currentDimension)});
                        rows.push({name: dimensionValues[0][i].value, dimension: currentDimension, metrics: this.getDimensionMetrics(currentDimension, dimensionValues[0][i].metadata), metadata: dimensionValues[0][i].metadata});
                    } else {
                        rows.push({name: dimensionValues[0][i].value, dimension: currentDimension, metrics: [], metadata: this.getDimensionMetadata(currentDimension, true)});
                        this.populateRows(dimensionValues.slice(1, dimensionValues.length), rows, [...currentDimension]); // populate rows for sub dimensions
                    }
                }
            }
        }

      /**
       * Sorts the values of the provided primary dimension by the value of a metric or metadata field
       * @param dimensionValues         values of the dimension that need to be sorted
       * @param dimension               Dimension definition of the supplied dimensionValues
       * @param column                  name of the dimension or metric to sort on
       * @param direction               sort direction asc|desc
       */
        public sortDimensionValues(dimensionValues: DimensionValue[], dimension: DimensionTableDimension, column: string, direction: string): DimensionValue[] {
            const dimensionIndex = this.dimensions.map(d => d.name).indexOf(dimension.name);
            const metricsIndex = this.metrics.map(d => d.name).indexOf(column);
            const metadataIndex = this.metadata ? this.metadata.indexOf(column) : -1;

            // create sort index
            let sortIndex = dimensionValues.map((dimensionValue) => {
                const dimensionRows = this.rows.filter((row) => row.dimensions[dimensionIndex] === dimensionValue.value && row.metadata.join('|') === dimensionValue.metadata.join('|')); // find rows for this dimension
                let metricSortValues: number[] = [];
                let metadataSortValues: string[] = [];
                let metricSortValue: number = 0;
                let metadataSortValue: string = '';
                if(metricsIndex >= 0) {
                    metricSortValues = dimensionRows.map((row) => Number(row.metrics[metricsIndex])).sort((a, b) => a - b);
                    metricSortValue = metricSortValues[metricSortValues.length-1];
                }
                if(metadataIndex >= 0) {
                    metadataSortValues = dimensionRows.map((row) => row.metadata[metadataIndex]).sort();
                    metadataSortValue = metadataSortValues[metadataSortValues.length-1];
                }
                return {dimensionValue, metricSortValues, metadataSortValues, metricSortValue, metadataSortValue};
            })

            // sort the sort index
            sortIndex = sortIndex.sort((a, b) => {
                if(direction === 'asc' && metricsIndex >= 0) {
                    // return a.metricSortValues[0] - b.metricSortValues[0];
                    // return a.metricSortValues[a.metricSortValues.length-1] - b.metricSortValues[b.metricSortValues.length-1];
                    return a.metricSortValue - b.metricSortValue;
                }
                if(direction === 'desc' && metricsIndex >= 0) {
                  // return b.metricSortValues[b.metricSortValues.length-1] - a.metricSortValues[a.metricSortValues.length-1];
                  return b.metricSortValue - a.metricSortValue;
                }
                if(direction === 'asc' && metadataIndex >= 0) {
                    // return a.metadataSortValues[a.metadataSortValues.length-1].localeCompare(b.metadataSortValues[b.metadataSortValues.length-1]);
                    return a.metadataSortValue.localeCompare(b.metadataSortValue);
                }
                if(direction === 'desc' && metadataIndex >= 0) {
                    // return a.metadataSortValues[a.metadataSortValues.length-1].localeCompare(b.metadataSortValues[b.metadataSortValues.length-1]) * -1;
                    return a.metadataSortValue.localeCompare(b.metadataSortValue) * -1;
                }
                if(direction === 'asc') {
                  return a.dimensionValue.value.localeCompare(b.dimensionValue.value);
                }
                if(direction === 'desc') {
                  return a.dimensionValue.value.localeCompare(b.dimensionValue.value) * -1;
                }
                return 0
            });

            return sortIndex.map((dsv) => dsv.dimensionValue);
        }


        /**
         * Returns the metrics for a dimension. Only return metrics for exact dimension
         */
        public getDimensionMetrics(dimensions: string[], metadata: string[]): string[] {
          // const row = this.rows.find(r => r.key === dimensions.join('|'));
          // const row = this.rows.find(r => JSON.stringify(r.dimensions) === JSON.stringify(dimensions));
          // const row = this.rows.find(r => JSON.stringify(r.dimensions) === JSON.stringify(dimensions) && JSON.stringify(r.metadata) === JSON.stringify(metadata));
          const row = this.rows.find(r => JSON.stringify(r.dimensions) === JSON.stringify(dimensions) && (metadata.length === 0 || (metadata.length > 0 && JSON.stringify(r.metadata) === JSON.stringify(metadata))));
          if(row) {
              return row.metrics;
          }
          // return [];
          return this.metrics.map((metric) => '');
        }

        public getDimensionMetadata(dimensions: string[], findByPrimaryDimension: boolean = false): string[] {
            let row;
            if(!findByPrimaryDimension) {
                row = this.rows.find(r => JSON.stringify(r.dimensions) === JSON.stringify(dimensions));
                // row = this.rows.find(r => r.key === dimensions.join('|'));
            } else {
                row = this.rows.find(r => r.dimensions[0] === dimensions[0]);
            }

            if(row) {
                return row.metadata;
            }
            return [];
        }



        public getTableCellStyleClass(row: {name: string; dimension: string[]; metrics: string[]}): string {
            let styleClass = 'table__cell';
            if(row.dimension.length === 1 && row.metrics.length === 0) {
                styleClass += ' table__cell--primary-dimension';
            }
            if(row.metrics.length === 0) {
                styleClass += ' table__cell--dimension';
            }
            return styleClass;
        }

        public isPrimaryDimensionRow(row: {name: string; dimension: string[]; metrics: string[]; metadata: string[]}): boolean {
            return row.dimension.indexOf(row.name) === 0;
        }

        public getFormattedMetricValue(metric: string, index: number): string {
            const metricDefinition = this.metrics[index];
            if(metricDefinition && metricDefinition.formatter && metric !== '') {
                return metricDefinition.formatter.format(metric);
            }
            return metric;
        }

        public getFormattedMetadataValue(metadata: string, index: number): string {
          const metadataColumn = this.metadataColumns[index] ?? null;
          if(metadataColumn && metadataColumn.formatter && metadata !== '') {
            return metadataColumn.formatter.format(metadata);
          }
          return metadata;
        }


        /**
         * Return a router param config for vue router. Only return the config if link prop is supplied and for the first row of each primary dimension
         * @param row
         */
        public getRouterLinkConfig(row: {name: string; dimension: string[]; metrics: string[]; metadata: string[]}): {name: string, params: object}|null {
            if(this.link && row.dimension.length === 1) {
                return {name: this.link.routeName, params: this.getLinkParams(row)};
            }
            return null
        }

        public getLinkParams(row: {name: string; dimension: string[]; metrics: string[]; metadata: string[]}): object {
            let params: RouterParams = {};
            if(this.link && this.link.params) {
                this.link.params.forEach(p => {
                    if(p.dimension) { // link based on dimension
                        const dimensionIndex = this.dimensions.map(d => d.name).indexOf(p.dimension);
                        if(dimensionIndex >= 0) {
                            params[p.paramKey] = row.dimension[dimensionIndex];
                        }
                    }
                    if(p.metadata) { // link based on metadata
                        const metadataIndex = this.metadata.indexOf(p.metadata);
                        if(metadataIndex >= 0) {
                            params[p.paramKey] = row.metadata[metadataIndex];
                        }
                    }
                })
            }
            return params;
        }


    }
