
    import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
    import {Getter} from "vuex-class";
    import moment from "moment";
    import Icon from "@/components/Icon.vue";
    import Button from "@/components/Button.vue";
    import DatePicker from "@/components/Input/DatePicker.vue";
    import Checkbox from "@/components/Input/Checkbox.vue";
    import SelectBox from "@/components/Input/SelectBox.vue";
    import {
        DateContext, DateContextEvent, DateContextPeriod, DateContextTransaction,
        DateContextType,
        DateRange,
        DateRangePreset,
        instanceOfDateContextEvent,
        instanceOfDateContextPeriod,
        instanceOfDateContextTransaction,
        Period
    } from "@/models/date-context";
    // import {TranslateResult} from "vue-i18n";
    // const namespace: string = 'core';

    @Component({
        components: {
            Icon,
            DatePicker,
            Checkbox,
            SelectBox,
            'te-button': Button,
        }
    })
    export default class DateContextMenu extends Vue {
        private contextType: DateContextType = DateContextType.period;
        private compare: boolean = false;
        // private period?: Period;
        private periodOption?: {id: string; label: string;}|null = null;
        private comparePeriodOption?: {id: string; label: string;}|null = null;
        private dateRange: DateRange = {min: moment().subtract(7, 'days').toDate(), max: new Date()};
        private compareDateRange: DateRange = {min: moment().subtract(14, 'days').toDate(), max: moment().subtract(7, 'days').toDate()};
        private selectedDateRangePreset: DateRangePreset|null = null;
        @Prop() private dateContext!: DateContext;
        @Getter('dateRangePresets') dateRangePresets!: DateRangePreset[];
        @Getter('periods') periods!: Period[];

        get isPeriodTab(): boolean {
            return this.contextType === DateContextType.period;
        }

        get isEventTab(): boolean {
            return this.contextType === DateContextType.event;
        }

        get isTransactionTab(): boolean {
            return this.contextType === DateContextType.transaction;
        }

        get isDisabledDateRange(): boolean {
            return this.selectedDateRangePreset === null || this.selectedDateRangePreset.id !== 'custom';
        }

        get formattedPeriods(): Array<{id: string; label: string;}> {
            return this.periods.map((p) => { return {id: p.id, label: p.name} });
        }

        get formattedComparePeriods(): Array<{id: string; label: string;}> {
            const id = this.periodOption ? this.periodOption.id : null;
            return this.periods.filter((p) => p.id !== id).map((p) => { return {id: p.id, label: p.name} });
        }

        get disableDateValidator(): (d: Date) => boolean {
            return (d: Date) => {
                if(this.isEventTab) return false;
                const now = new Date();
                return now.getTime() < d.getTime();
                // const tomorrow = new Date();
                // tomorrow.setDate(tomorrow.getDate()+1);
                // return tomorrow.getTime() < d.getTime();
            };
        }

        get disableDateCompareValidator(): (d: Date) => boolean {
            return (d: Date) => {
                return this.dateRange.min.getTime() < d.getTime();
            };
        }

        public setContextType(tab: DateContextType): void {
            this.contextType = tab;
        }

        public onSelectPeriodOptions(option: {id: string; label: string;}): void {
            this.periodOption = option;
            this.comparePeriodOption = this.formattedComparePeriods[0];
        }

        public onSelectComparePeriodOptions(option: {id: string; label: string;}): void {
            this.comparePeriodOption = option;
        }

        public setDateRangePreset(preset: DateRangePreset): void {
            this.selectedDateRangePreset = preset;
            this.setDateRange(preset.getDateRange());
        }

        public setDateRange(d: DateRange): void {
            this.dateRange = d;
            if(this.selectedDateRangePreset) {
                this.setCompareDate(this.selectedDateRangePreset.getDateRange(this.dateRange)); // init compare date range
            }
        }

        public setCompare(value: boolean): void {
            this.compare = value;
        }

        public setCompareDate(d: DateRange): void {
            this.compareDateRange = d;
        }

        public initDateContext(dateContext: DateContext): void {
            this.setContextType(dateContext.type);
            if(instanceOfDateContextPeriod(dateContext)) {
                const periodOption = this.periods.map((p) => {return {id: p.id, label: p.name}}).find((p) => p.id === dateContext.periodId);
                if(periodOption) this.periodOption = periodOption;
                const comparePeriodId = dateContext.compareTo.length > 0 ? dateContext.compareTo[0] : null;
                const comparePeriodOption = this.periods.map((p) => {return {id: p.id, label: p.name}}).find((p) => p.id === comparePeriodId);
                if(comparePeriodOption) {
                    this.compare = true;
                    this.comparePeriodOption = comparePeriodOption;
                } else {
                    this.comparePeriodOption = this.formattedComparePeriods[0];
                }
            }
            if(instanceOfDateContextEvent(dateContext) || instanceOfDateContextTransaction(dateContext)) {
                this.setDateRangePreset(dateContext.rangePreset);
                this.setDateRange(dateContext.range);
                if(dateContext.compareTo.length > 0) {
                    this.setCompare(true);
                    this.setCompareDate(dateContext.compareTo[0]);
                }
            }
        }

        @Watch('dateContext')
        nameChanged(newContext: DateContext) {
            this.initDateContext(newContext);
        }

        @Emit("apply")
        public onApply(): DateContextPeriod|DateContextEvent|DateContextTransaction|void {
            if(this.contextType === DateContextType.period && this.periodOption) {
                const compareTo = this.compare && this.comparePeriodOption ? [this.comparePeriodOption.id] : [];
                return {
                    type: this.contextType,
                    periodId: this.periodOption.id,
                    periodName: this.periodOption.label,
                    compareTo,
                }
            }
            if(this.dateRange && this.selectedDateRangePreset) {
                const compareTo = this.compare && this.compareDateRange ? [this.compareDateRange] : [];
                return {
                    type: this.contextType,
                    range: this.dateRange,
                    rangePreset: this.selectedDateRangePreset,
                    compareTo
                }
            }
        }

        @Emit("close")
        public onClose(): void {
            return;
        }

        created(): void {
            this.setDateRangePreset(this.dateRangePresets[0]);
            if(this.dateContext) this.initDateContext(this.dateContext);
        }
    }
