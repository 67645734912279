import {SeriesBuilder, ValueMap} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import {
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction,
    instanceOfDateContextTransaction,
    Period
} from '@/models/date-context';
import {DimensionTableRow} from '@/models/table';
import moment from 'moment';


export class DimensionTableSeriesBuilder extends SeriesBuilder {
    private dimensions: string[];
    private metrics: string[];
    private metadata: string[];

    constructor(
        response: AnalyticsApiQueryResponse,
        segments: SegmentSummery[],
        dimensions: string[],
        metrics: string[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
        metadata?: string[],
    ) {
        super(response, segments, periods, dateContext);
        this.dimensions = dimensions;
        this.metrics = metrics;
        this.metadata = metadata || [];
    }

    public build(): DimensionTableRow[] {
        if (this._response && this._response.data && this._response.data.rows) {
            return this._response.data.rows.map((row) => {
                const dimensions = this.getRowDimensions(row, this.dimensions);
                const metadata = this.getRowDimensions(row, this.metadata);
                return {
                    // dimensions: this.getRowDimensions(row),
                    // dimensions: this.getRowDimensions(row, this.dimensions),
                    // name: dimentions[dimentions.length - 1],
                    // primaryKey: [dimensions[0], ...metadata].join('|'), // key consist of primary dimension plus metadata dimensions
                    dimensions,
                    metrics: this.getRowMetrics(row),
                    metadata,
                };
            });
        }
        return [];
    }


    // private getRowDimensions(row: Row): string[] {
    //     const mapDimensionValue = this.mapDimensionValue.bind(this); // bind this context to map
    //     const returnDimensionIndexes = this.dimensions.map((rd) => this._response.data.dimensions.map((dd) => dd.name).indexOf(rd)); // dimension indexes of the paymentMethodDimensions that need to be returned
    //     return row.dimensions
    //         .map((d, i) => ({value: d, index: returnDimensionIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
    //         .filter((d) => d.index >= 0) // filter out values that are not in returnDimensionIndexes
    //         .sort((a, b) => a.index - b.index) // sort array in order of returnDimensionIndexes
    //         .map((d) => d.value) // return only values
    //         .map(mapDimensionValue); //
    // }

    private getRowDimensions(row: Row, dimensions: string[]): string[] {
        const mapDimensionValue = this.mapDimensionValue.bind(this); // bind this context to map
        const returnDimensionIndexes = dimensions.map((rd) => this._response.data.dimensions.map((dd) => dd.name).indexOf(rd)); // dimension indexes of the paymentMethodDimensions that need to be returned
        return row.dimensions
            .map((d, i) => ({value: d, index: returnDimensionIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
            .filter((d) => d.index >= 0) // filter out values that are not in returnDimensionIndexes
            .sort((a, b) => a.index - b.index) // sort array in order of returnDimensionIndexes
            .map((d) => d.value) // return only values
            .map(mapDimensionValue); //
    }

    private getRowMetrics(row: Row): string[] {
        const returnMetricIndexes = this.metrics.map((rm) => this._response.data.metrics.map((dm) => dm.name).indexOf(rm)); // metric indexes of the paymentMethodMetrics that need to be returned
        return row.metrics
            .map((d, i) => ({value: d, index: returnMetricIndexes.indexOf(i)})) // create temp model with index prop for sorting and filtering
            .filter((d) => d.index >= 0) // filter out values that are not in returnMetricIndexes
            .sort((a, b) => a.index - b.index) // sort array in order of returnMetricIndexes
            .map((d) => d.value);  // return only values
    }



    /**
     * Replace segment and period id with name, else return provided value
     * @param value
     * @param index
     */
    private mapDimensionValue(value: string, index: number): string {
        return this._formatDimensionValue(value, this.dimensions[index]);
    }



}
