
    import {Component, Emit, Prop, Vue} from "vue-property-decorator";
    import {SegmentSummery, SegmentType} from "@/models/segment";

    @Component
    export default class SegmentCart extends Vue {
        @Prop() private segment!: SegmentSummery;

        get segmentStyleClass(): string {
            let styleClass = 'segment-cart';
            if(this.isNew) styleClass += ' segment-cart--new';
            if(this.isSelectable) styleClass += ' segment-cart--selectable';
            return styleClass;
        }

        get isNew(): boolean {
            return this.segment.type === SegmentType.new;
        }

        get isSelectable(): boolean {
            // return this.segment.type === SegmentType.new || this.segment.type !== SegmentType.prebuild;
            return true;
        }

        public formatPercentage(percentage: number): string {
            return (percentage*100).toFixed(0);
        }

        @Emit("click")
        public onClick(): SegmentSummery {
            return this.segment;
        }
    }
