
// https://medium.com/@prins.p/create-the-cartesian-product-using-typescript-c8f5efaf81e5

// type IElement = string | number;
type IElement = string;

interface IResultObj {
    [key: string]: IElement;
}

/**
 * Returns the cartesian product of array elements.
 * The cartesian product values can be named by a key by specifying a keys array (see options).
 * @param arrays
 * @param options {object}
 * @param options.excludeEmpty {boolean} exclude empty arrays? Defaults to true
 * @param options.keys {tuple} a tuple containing the keys to assign to the values of the cartesian product. The keys should be in the order in which the arrays are passed.
 */
export function cartesian(
    arrays: IElement[][],
    options: { excludeEmptyArrays?: boolean; keys: string[] },
): IResultObj[];
export function cartesian(
    arrays: IElement[][],
    options?: { excludeEmptyArrays?: boolean; keys: undefined },
): IElement[][];
export function cartesian(
    arrays: IElement[][],
    options?: { excludeEmptyArrays?: boolean; keys?: string[] },
): IResultObj[] | IElement[][] {
    const { excludeEmptyArrays, keys } = {
        excludeEmptyArrays: options?.excludeEmptyArrays || true,
        keys: options?.keys || undefined,
    };

    const withoutEmpty = excludeEmptyArrays ? arrays.filter((arr) => arr.length > 0) : arrays;

    const cartesianProduct = withoutEmpty.reduce(
        (cp: any[][], curr) => cp.flatMap((cpVal) => curr.map((currVal) => cpVal.concat(currVal))),
        [[]],
    );

    return keys ? addKeysToCartesianValues(keys, cartesianProduct) : cartesianProduct;
}

function addKeysToCartesianValues(keys: string[], cartesianProduct: IElement[][]): IResultObj[] {
    return cartesianProduct.reduce((cp: IResultObj[], curr: IElement[]) => {
        return cp.concat(
            curr.reduce((obj: IResultObj, curr2: IElement, index: number) => {
                const keyName = keys[index] || index;
                obj[keyName] = curr2;
                return obj;
            }, {}),
        );
    }, []);
}
