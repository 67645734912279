import moment from 'moment';


export interface Formatter {
    format(value: string): string;
}


export class FormatPercentage implements Formatter {
    private fractionDigits: number;

    constructor(fractionDigits?: number) {
        this.fractionDigits = fractionDigits ?? 1;
    }

    public format(value: string|number): string {
        const v = parseFloat(String(value)) * 100;
        return v.toFixed(this.fractionDigits) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '%';
    }
}


export class FormatNumber implements Formatter {
    private fractionDigits: number;
    private prefix?: string|null;
    private suffix?: string|null;

    constructor(fractionDigits?: number|null, prefix?: string|null, suffix?: string|null) {
        this.prefix = prefix;
        this.suffix = suffix;
        this.fractionDigits = fractionDigits ?? 1;
    }

    public format(value: string|number): string {
        const prefix = this.prefix ? this.prefix + ' ' : '';
        const suffix = this.suffix ? ' ' + this.suffix : '';
        return prefix + parseFloat(String(value)).toFixed(this.fractionDigits) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + suffix;
    }
}


export class FormatCurrencyAmount implements Formatter {
    private fractionDigits: number;
    private currencySymbol: string;

    constructor(currencySymbol: string, fractionDigits?: number) {
        this.currencySymbol = currencySymbol;
        this.fractionDigits = fractionDigits ?? 2;
    }

    public format(value: string|number): string {
        if(value === 0 || value === '') {
            return '-';
        }
        return this.currencySymbol + ' ' + parseFloat(String(value)).toFixed(this.fractionDigits) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
}



export class FormatDateString implements Formatter {
    private inputFormat: string;
    private outputFormat: string;

    constructor(inputFormat: string, outputFormat: string) {
        this.inputFormat = inputFormat;
        this.outputFormat = outputFormat;
    }

    public format(value: string): string {
        return moment(value, this.inputFormat).format(this.outputFormat);
    }
}
