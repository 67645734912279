

/**
 * Returns the number of days between the start of the provided date and the compare date
 */
export function getDateDifferance(date: Date, compare: Date, unit: string): number {
    if (unit === 'days') {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        const utc2 = Date.UTC(compare.getFullYear(), compare.getMonth(), compare.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
    throw new Error(`Unknown date offset unit: "${unit}".`);
}


/**
 * Parses date string from analytics api formatted in oone of the following formats: YYYYMMDD, YYYYMMDDHH or YYYYMMDDHHMM
 */
export function parseDate(value: string): Date {
    if (value.length === 12) {
        value = [value.slice(0, 10), ':', value.slice(10)].join('');
    }
    if (value.length >= 10) {
        value = [value.slice(0, 8), ' ', value.slice(8)].join('');
    }
    if (value.length >= 8) {
        value = [value.slice(0, 6), '-', value.slice(6)].join('');
        value = [value.slice(0, 4), '-', value.slice(4)].join('');
    }
    return new Date(value);
}
